const productDetailComponent = document.querySelector('.product-section');
if (productDetailComponent) {
  const productTabsContainer = document.querySelector(
    '.product-section__tabs-container'
  );

  if (productTabsContainer) {
    const productsTabsButtons = productTabsContainer.querySelectorAll(
      '.product-section__tab-btn'
    );
    const productsTabsItems = productTabsContainer.querySelectorAll(
      '.product-section__tab-item'
    );

    productsTabsButtons.forEach((btn, i) =>
      btn.addEventListener('click', (e) => {
        productsTabsButtons.forEach((btn) => btn.classList.remove('active'));
        productsTabsItems.forEach((btn) => btn.classList.remove('active'));

        btn.classList.add('active');
        productsTabsItems[i].classList.add('active');
      })
    );
  }
}
