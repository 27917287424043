window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

/*window.initFocusInputLogic = function initFocusInputLogic() {
  if (document.getElementsByClassName('meta-input')[0]) {
    const inputsFieldsArr = document.querySelectorAll('.meta-input');

    inputsFieldsArr.forEach((inputField, index) => {
      if (!inputField.classList.contains('is-init')) {
        const input = inputField.querySelector('input');

        /!*console.log(input.value.trim());*!/

        if (input.getAttribute('value')) {
          inputField.classList.add('focused');
        }

        input.addEventListener('focus', function () {
          inputField.classList.add('focused');
        });

        input.addEventListener('blur', function () {
          if (!input.value) {
            inputField.classList.remove('focused');
          }
        });

        inputField.classList.add('is-init');
      }
    });
  }
};

initFocusInputLogic();*/

const phoneNumberInput = document.getElementsByClassName('phone-input')[0];

if (phoneNumberInput) {
  const phoneNumberInputArr = document.querySelectorAll('.phone-input');

  const maskOptions = {
    mask: '+{38} (000) 00-00-000',
  };

  const validatePhoneNumberLength = (value) => {
    return value.replace(/\D/g, '').length === 12;
  };

  const formatPhoneNumberInput = (input) => {
    const currentValue = input.value;
    const infoInput = input.parentElement.querySelector('.input-info');

    if (validatePhoneNumberLength(currentValue)) {
      if (Number(input.value[5]) !== 0) {
        infoInput.classList.add('active');
        infoInput.classList.remove('active-valid');
        infoInput.classList.add('active-not-valid');
        infoInput.textContent =
          'Код оператору має починатися з 0, наприклад 099';
      } else {
        infoInput.classList.add('active');
        infoInput.classList.remove('active-not-valid');
        infoInput.classList.add('active-valid');
        infoInput.textContent = 'Номер телефону введено коректний';
        setTimeout(function () {
          infoInput.classList.remove('active');
        }, 2000);
      }
    } else {
      infoInput.classList.add('active');
      infoInput.classList.remove('active-valid');
      infoInput.classList.add('active-not-valid');
      infoInput.textContent =
        'Введіть коректний номер телефону, має бути 12 символів';
    }
  };

  phoneNumberInputArr.forEach((input, index) => {
    const mask = IMask(input, maskOptions);

    input.addEventListener('click', (event) => {
      if (input.value === '+38 (999) 99-99-99') {
        input.value = '';
        /*input.classList.add('is-started-validations');*/
      }
    });

    input.addEventListener('blur', () => {
      if (input.value === '') {
        input.value = '+38 (999) 99-99-99';
        input.parentElement.classList.add('focused');
      }
    });

    input.addEventListener('input', (event) => {
      formatPhoneNumberInput(event.target);
    });
  });
}

window.counterInputsFunc = function counterInputsFunc() {
  const checkoutInputsArr = document.querySelectorAll('.checkout-input');

  checkoutInputsArr.forEach((input) => {
    input.addEventListener('input', function (event) {
      let value = this.value.trim();

      value = value.replace(/\D/g, '');

      if (parseInt(value) > 999) {
        value = '999';
      }

      this.value = value;
    });
  });
};
counterInputsFunc();

window.decreaseInputNumber = function decreaseInputNumber(inputId) {
  const input = document.getElementById(inputId);
  input.stepDown();

  const parentBlock = input.closest('.counter-parent-toggle');
  if ((Number(input.value) === 0 || !input.value) && parentBlock.classList.contains('active')) {
    parentBlock.classList.remove('active');
  }

  const event = new Event('change');
  input.dispatchEvent(event);
};

window.decreaseRemoveInputNumber = function decreaseInputNumber(inputId) {
  const input = document.getElementById(inputId);
  input.value = 1;

  const parentBlock = input.closest('.counter-parent-toggle');
  parentBlock.classList.remove('active');
};

window.increaseInputNumber = function increaseInputNumber(inputId) {
  const input = document.getElementById(inputId);
  input.stepUp();

  const event = new Event('change');
  input.dispatchEvent(event);

  let value = input.value.trim();

  value = value.replace(/\D/g, '');

  if (parseInt(value) > 999) {
    value = '999';
  }

  input.value = value;
};
