if (document.getElementsByClassName('title-decor')[0]) {
  function afterFontsLoaded() {
    const titlesDecorArr = document.querySelectorAll('.title-decor');
    const sharedSVG = document
      .getElementById('title-decor-svg')
      .cloneNode(true);

    titlesDecorArr.forEach((titleDecorEl) => {
      titleWidthCalc(titleDecorEl);

      generateTitleDecor(titleDecorEl);

      const titleDecorPath = titleDecorEl.querySelector('path');
      getPathLength(titleDecorPath);
    });

    function generateTitleDecor(element) {
      const svg = sharedSVG.cloneNode(true);
      element.appendChild(svg);
    }

    function getPathLength(figure) {
      figure.style.strokeDasharray = `${figure.getTotalLength()}`;
      figure.style.strokeDashoffset = `${figure.getTotalLength()}`;
    }

    function titleWidthCalc(element) {
      const parentContainer = element.parentElement;
      const heading = element.previousElementSibling;
      parentContainer.style.setProperty(
        '--title-width',
        `${Math.ceil(heading.clientWidth + 2)}px`
      );
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth >= 640) {
        for (let i = 0; i < titlesDecorArr.length; i++) {
          titleWidthCalc(titlesDecorArr[i]);
        }
      }
    });
  }

  document.fonts.ready.then(afterFontsLoaded);
}
