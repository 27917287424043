import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let productsSwiperWr = document.querySelectorAll('.products-swiper-wr');
productsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 16,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        641: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 2.5,
        },
        951: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1024: {
          spaceBetween: 24,
          slidesPerView: 3,
        },
        1279: {
          spaceBetween: 14,
          slidesPerView: 4,
        },
        1300: {
          spaceBetween: 16,
          slidesPerView: 4,
        },
        1400: {
          spaceBetween: 24,
          slidesPerView: 4,
        },
      },
      /*autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },*/
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
        touchStart: function (event) {
          /*// Проверка на наличие целевого элемента
          if (
            event.target &&
            (event.target.classList.contains('choices') ||
              event.target.closest('.choices'))
          ) {
            console.log(12345);
            this.allowTouchMove = false;
          }*/
        },
        touchEnd: function (event) {
          const choicesContainers = document.querySelectorAll('.choices');

          function checkIfAnyOpen() {
            const anyOpen = Array.from(choicesContainers).some((container) =>
              container.classList.contains('is-open')
            );
            console.log('Any open:', anyOpen);

            if (anyOpen) {
              swiper.allowTouchMove = false;
            } else {
              swiper.allowTouchMove = true;
            }
          }

          setTimeout(function () {
            checkIfAnyOpen();
          }, 50);
        },
      },
    });
    /*swiperObserver(swiper);*/
  }
});
