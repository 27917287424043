import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let catalogueSwiperWr = document.querySelectorAll('.catalogue-swiper-wr');
catalogueSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    const swiperMobileHelper = swiperEl.querySelector('.swiper-mobile-helper');

    swiperOnResize('(max-width: 768px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 'auto',
      spaceBetween: 8,
    });

    setTimeout(function () {
      swiperMobileHelper.classList.add('show');
    }, 300);
  }
});

const categoriesArr = document.querySelectorAll('.catalogue-section__category');

if(categoriesArr.length > 0) {
  categoriesArr.forEach((category, i) => {
    category.addEventListener('click', () => {
      if(!category.classList.contains('active')) {
        for (let j = 0; j < categoriesArr.length; j++) {
          categoriesArr[j].classList.remove('active');
        }
        category.classList.add('active');
      } else {
        category.classList.remove('active');
      }
    })
  })
}
