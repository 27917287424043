import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let joinUsSwiperWr = document.querySelectorAll('.join-us-swiper-wr');
joinUsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 0,
      slidesPerView: 1,
      // speed: 800,
      // threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        // 640: {
        //   spaceBetween: 16,
        //   slidesPerView: 2,
        // },
        // 1024: {
        //   spaceBetween: 24,
        //   slidesPerView: 2,
        // },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      // on: {
      //   afterInit: function () {
      //     wrapper.scrollWidth >= wrapper.clientWidth
      //       ? wrapper.classList.remove('justify-center')
      //       : wrapper.classList.add('justify-center');
      //   },

      //   resize: function () {
      //     wrapper.scrollWidth >= wrapper.clientWidth
      //       ? wrapper.classList.remove('justify-center')
      //       : wrapper.classList.add('justify-center');
      //   },
      // },
    });
    swiperObserver(swiper);
  }
});
